<template>
  <div class="recruitments">
    <!-- 职位列表 -->
    <div style="margin-bottom:35px">
      <div class="postlist" v-for="item in list" :key="item" @click="todetail(item)">
        <!-- <router-link :to="'/recruitment/detail/' + item.JId"> -->
        <div>
          <!-- 一 职位 薪资 -->
          <div class="listtitle">
            <span>申请职位: <span style="color:#0076f6">{{item.JTitle}}</span> </span>
            <span style="" v-if="item.StatusDesc">{{item.StatusDesc}}</span>
            <span style="color:red" v-if="item.EliminateReason">已{{item.EliminateReason}}</span>
          </div>
          <!-- 四 招募人 地址 -->
          <div class="listfore">
            <span>
              企业: {{item.Enames}}
            </span>
            <span>
              投递时间: {{item.DeliverTime}}
            </span>
          </div>
          <!-- 淘汰原因 -->
          <div class="taotai" v-if="item.EliminateReason">
            不合适原因: {{item.EliminateReason}}
          </div>
        </div>
      </div>
      <!-- 没有数据时 -->
      <div class="kongshow" v-if="kongshow"> 
        还没有投递简历~
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { getToken, setToken } from "@/utils/auth";
import {
  JS_GetMyReDeliverList
} from "@/api/ResumeJS";
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
export default {
  data() {
    return {
      search: "", //搜索
      itemmark:'', //职位详情
      listdata:{
        accToken:getToken(), //token
      },
      kongshow:false,
      list: [], //列表
    };
  },
  created(){
    var token = getToken();
    if (token == null) {
      this.$router.push({
        name: "recruitment",
      });
    }
    this.GetJobPage();
  },
  methods: {
    //小程序跳转到小程序
    // 获取职业列表
    GetJobPage(){
      JS_GetMyReDeliverList(this.listdata).then((res)=>{
        console.log(res);
        if(res.data.code == 0){
          if(res.data.data.length == 0){
            this.kongshow = true;
            this.list = [];
          }else{
            this.list = res.data.data;
          }
        }else{
          Toast.fail(res.data.msg);
        }  
      })
    },
  }
};
</script>
<style scoped>
.recruitments {
  background: #f2f8f9;
  height: 100%;
}
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
.recruitments .van-nav-bar__title {
  color: #fff;
}
.recruitments .van-nav-bar__content {
  background: #0188fb;
}
.postlist {
  margin: 5px 0px;
  background: #fff;
}
.postlist .listtitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 700;
  color: #000;
}
.postlist .listtwo {
  padding: 0px 10px;
  font-size: 12px;
}
.postlist .listtwo span {
  padding: 6px;
  background: rgb(238, 237, 237);
  margin-right: 8px;
  color: #666;
}
.postlist .listthree {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  font-size: 14px;
}
.postlist .listthree span {
  margin-right: 30px;
  color: #666;
}
.postlist .listfore {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0px 0px 15px 10px;
  font-size: 13px;
}
.postlist .listfore span {
  margin-right: 15px;
  color: #a3a7a8;
}
.van-search__action {
  margin-right: 10px !important;
  color: #fff;
}
.van-search__action:active {
  background: #0188fb;
}
.kongshow {
  text-align: center;
  width: 90%;
  margin: 30px 5%;
  height: 50px;
  line-height: 50px;
  border-radius: 15px;
  background: #dce1e2;
}
.taotai {
  font-size: 15px;
  color: red;
  margin: 0px 13px;
  padding-bottom: 15px;
}
</style>